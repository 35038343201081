import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { StockDto } from 'src/app/swagger';
import { PrintService } from 'src/app/core/services/print.service';

@Component({
	selector: 'app-stock-location-print',
	template: `
		<div
			class="m-0 p-0 border-none bg-transparent transform rotate-90 translate-x-[-25%] translate-y-[140%] origin-center h-[29mm] w-[89mm] flex items-center"
		>
			<div class="flex flex-row gap-x-1">
				<div class="flex items-center w-[30mm]">
					<img
						[src]="QRCodeImg"
						class="w-11/12 h-auto"
						alt="error generating the qrcode"
					/>
				</div>
				<div class="flex-col flex-nowrap text-xs w-[85mm]">
					<div class="text-base break-words">
						{{ stock?.name }}
					</div>
					<div class="mt-1 text-xs break-words">
						{{ stock?.description }}
					</div>
				</div>
			</div>
		</div>
	`,
	styles: [
		`
			@media screen {
				:host {
					display: none;
				}
			}
		`,
	],
	standalone: true,
})
export class StockLocationPrintComponent implements OnDestroy {
	QRCodeImg: string;
	stock: StockDto;

	private subs: Subscription = new Subscription();

	constructor(private printService: PrintService) {
		this.subs.add(
			this.printService.stockLocationOrder.subscribe(async (x) => {
				if (x != null) {
					this.stock = x;
					this.QRCodeImg = await this.printService.createQRCode(
						this.stock.locationCode,
					);
					this.printService.isPrintingStockLocation$.next(true);
					this.printService.onDataReady(
						this.printService.isPrintingStockLocation$,
					);
				}
			}),
		);
	}

	ngOnDestroy(): void {
		if (this.subs) this.subs.unsubscribe();
	}
}
