import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PrintService } from 'src/app/core/services/print.service';
import { UserService } from 'src/app/core/services/user.service';
import { StockLocationPrintComponent } from '../stock-location-print/stock-location-print.component';
import { OutOfStockArticlePdfPrintComponent } from '../article-pdf-print/article-pdf-print.component';
import { PickupPrintComponent } from '../pickup-print/pickup-print.component';
import { InputPrintComponent } from '../input-print/input-print.component';
import { AsyncPipe, NgClass } from '@angular/common';

@Component({
	selector: 'app-print-wrapper',
	template: `
		<div
			[ngClass]="
				(printService.isPrintingInput$ | async) ? 'flex' : 'hidden'
			"
		>
			<app-input-print [rotate]="true" />
		</div>
		<div
			[ngClass]="
				(printService.isPrintingPickup$ | async) ? 'flex' : 'hidden'
			"
		>
			<app-pickup-print />
		</div>
		<div
			[ngClass]="
				(printService.isPrintingArticlePdf$ | async) ? 'flex' : 'hidden'
			"
		>
			<app-article-pdf-print />
		</div>
		<div
			[ngClass]="
				(printService.isPrintingStockLocation$ | async)
					? 'flex'
					: 'hidden'
			"
		>
			<app-stock-location-print />
		</div>
	`,
	standalone: true,
	imports: [
		InputPrintComponent,
		PickupPrintComponent,
		OutOfStockArticlePdfPrintComponent,
		StockLocationPrintComponent,
		NgClass,
		AsyncPipe,
	],
})
export class PrintWrapperComponent {
	constructor(public printService: PrintService) {}
}
