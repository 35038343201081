import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class BrowserDetectionService {
	usableBrowser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		true,
	);
	agent = window.navigator.userAgent.toLowerCase();
	closed: boolean = false;

	constructor() {
		const agent = window.navigator.userAgent.toLowerCase();
		this.usableBrowser.next(this.isEdge(agent) || this.isChrome(agent));
	}

	private isEdge = (agent: string) =>
		agent.indexOf('edge') > -1 || agent.indexOf('edg') > -1;
	private isChrome = (agent: string) => agent.indexOf('chrome') > -1;
	private isOpera = (agent: string) => agent.indexOf('opr') > -1;
	private isIE = (agent: string) => agent.indexOf('trident') > -1;
	private isFirefox = (agent: string) => agent.indexOf('firefox') > -1;
	private isSafari = (agent: string) => agent.indexOf('safari') > -1;
}
