import { Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { RepeatedOrderGuard } from 'src/app/core/guards/repeated-order-guard';
import { AppWrapperComponent } from './views/auth/app-wrapper/app-wrapper.component';
import { authGuard } from './views/auth/auth.guard';
import { LoginFailedComponent } from './views/auth/login-failed/login-failed.component';
import { LoginComponent } from './views/auth/login/login.component';
import { LogoutComponent } from './views/auth/logout/logout.component';
import { OrdersComponent } from './views/orders/orders.component';

export const routes: Routes = [
	{ path: 'login', canActivate: [MsalGuard], component: LoginComponent },
	{ path: 'auth', component: MsalRedirectComponent },
	{ path: 'logout', component: LogoutComponent },
	{ path: 'login-failed', component: LoginFailedComponent },
	{
		path: 'registration',
		loadComponent: () =>
			import(
				'./views/registration/reg-wrapper/reg-wrapper.component'
			).then((x) => x.RegWrapperComponent),
		children: [
			{
				path: '',
				loadComponent: () =>
					import('./views/registration/registration.component').then(
						(x) => x.RegistrationComponent,
					),
			},
			{
				path: ':org-id',
				loadComponent: () =>
					import('./views/registration/registration.component').then(
						(x) => x.RegistrationComponent,
					),
			},
			{
				path: ':org-id/:hashed-email',
				loadComponent: () =>
					import('./views/registration/registration.component').then(
						(x) => x.RegistrationComponent,
					),
			},
		],
	},
	{
		path: 'registration-error',
		loadComponent: () =>
			import('./views/registration/reg-error/reg-error.component').then(
				(x) => x.RegErrorComponent,
			),
	},
	{
		path: '',
		component: AppWrapperComponent,
		canActivate: [authGuard],
		children: [
			{
				path: '',
				redirectTo: '/orders',
				pathMatch: 'full',
			},
			{
				path: 'orders',
				component: OrdersComponent,
			},
			{
				path: 'orders/create',
				loadComponent: () =>
					import(
						'./views/orders/order-creation/new-pickup-card/new-pickup-card.component'
					).then((x) => x.NewPickupComponent),
			},
			{
				path: 'orders/create-recurrent',
				loadComponent: () =>
					import(
						'./views/orders/order-creation/new-pickup-card/new-pickup-card.component'
					).then((x) => x.NewPickupComponent),
				canActivate: [RepeatedOrderGuard],
			},
			{
				path: 'orders/display',
				loadComponent: () =>
					import(
						'./views/orders/order-details/order-details.component'
					).then((x) => x.OrderDetailsComponent),
			},
			{
				path: 'stock-input',
				loadComponent: () =>
					import(
						'./views/stock-input/overview/stock-input-overview.component'
					).then((x) => x.StockInputOverviewComponent),
			},
			{
				path: 'pharmacysettings',
				loadComponent: () =>
					import(
						'./views/pharmacy-settings/pharmacy-settings.component'
					).then((x) => x.PharmacySettingsComponent),
			},
			{
				path: 'workstationsettings',
				loadComponent: () =>
					import(
						'./views/workstation-settings/workstation-settings.component'
					).then((x) => x.WorkstationSettingsComponent),
			},
			{
				path: 'profile',
				loadComponent: () =>
					import('./views/profile/profile.component').then(
						(x) => x.ProfileComponent,
					),
			},
			{
				path: 'partners',
				loadComponent: () =>
					import('./views/partners/partners.component').then(
						(x) => x.PartnersComponent,
					),
			},
			{
				path: 'scheduled-orders',
				loadComponent: () =>
					import(
						'./views/scheduled-orders/scheduled-order.component'
					).then((x) => x.RecurrentOrderComponent),
				canActivate: [RepeatedOrderGuard],
			},
			{
				path: 'scheduled-orders/display',
				loadComponent: () =>
					import(
						'./views/scheduled-orders/scheduled-orders-details/scheduled-order-details.component'
					).then((x) => x.ScheduledOrdersDetailsComponent),
			},
			{
				path: 'out-of-stock-articles',
				loadComponent: () =>
					import(
						'./views/out-of-stock-articles/out-of-stock-articles.component'
					).then((x) => x.OutOfStockArticlesComponent),
				canActivate: [RepeatedOrderGuard],
			},
			{
				path: 'dispensed-articles',
				loadComponent: () =>
					import(
						'./views/dispensed-articles/dispensed-articles.component'
					).then((x) => x.DispensedArticlesComponent),
				canActivate: [RepeatedOrderGuard],
			},
			{
				path: 'stock-management',
				loadComponent: () =>
					import(
						'./views/stock-management/stock-overview/stock-overview.component'
					).then((x) => x.StockOverviewComponent),
			},
		],
	},
	{ path: '**', component: LoginComponent },
];
