<app-guest-nav></app-guest-nav>

<div class="flex justify-center m-8">
	@if (showLoader) {
		<div class="flex justify-center">
			<rowa-activity-indicator />
		</div>
	}
	@if (showUI) {
		<div class="flex flex-col justify-center w-1/2 text-center gap-y-6">
			<div>{{ 'AUTH.LOGIN.INTRO' | translate }}</div>

			<rowa-dropdown-list
				[items]="pharmacies"
				keyField="name"
				[hasFilter]="true"
				[(ngModel)]="currentPharma"
				name="pharmacySelection"
				[disabled]="isBusy"
				[options]="{
					hideLabel: true,
				}"
			>
				<ng-template #selected let-selected>
					<span>{{ selected?.name }}</span>
				</ng-template>

				<ng-template #option let-option>
					<div>{{ option?.name }}</div>
				</ng-template>
			</rowa-dropdown-list>

			<app-button
				class="flex justify-center"
				[text]="isBusy ? 'AUTH.LOGIN.BUST' : 'AUTH.LOGIN.LOGIN'"
				(click)="selectPharmacy(currentPharma.id)"
				[disabled]="isBusy"
			/>

			@if (isBusy) {
				<rowa-activity-indicator class="flex justify-center" />
			}
		</div>
	}
</div>

@if (showError) {
	<div class="text-center">
		<h1 class="m-8 font-bold text-s-3-500">
			{{ 'AUTH.LOGIN.ERROR' | translate }}
		</h1>
		<p>{{ errorText }}</p>
		<p class="mb-8">{{ errorDetail }}</p>
		<app-button
			text="AUTH.LOGIN.RETRY"
			theme="secondary"
			(click)="tryAgain()"
		/>
		<app-button
			text="AUTH.LOGIN.LOGOUT"
			theme="secondary"
			(click)="doLogout()"
		/>
	</div>
}
