import { NgClass } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription, skipWhile } from 'rxjs';
import { ORDERSTATE, OrdersCount } from 'src/app/swagger';
import { PharmacySettingsService } from 'src/app/core/services/pharmacy-settings.service';
import { OrderOverviewService } from './../order-overview/order-overview.service';

type filter_helper = {
	active: boolean;
	text: string;
	state: ORDERSTATE | undefined;
	count: number;
	active_class: string;
	hover_class: string;
	id: string;
};

@Component({
	selector: 'app-orders-filter',
	template: `
		<div class="flex flex-row items-center gap-x-4">
			@for (filter of filters; track filter.id; let isFirst = $first) {
				<div
					[id]="filter.id"
					class="px-3 py-2 rounded-full cursor-pointer !bg-opacity-10 text-g-3-700"
					[ngClass]="
						filter.active ? filter.active_class : filter.hover_class
					"
					(click)="changeFilter(filter.state)"
				>
					{{ filter.text | translate }}
					<span class="text-g-3-300"> ({{ filter.count }}) </span>
				</div>
				@if (isFirst) {
					<div
						class="h-6 border cursor-default w-[1px] border-g-1-400"
					></div>
				}
			}
		</div>
	`,
	standalone: true,
	imports: [NgClass, TranslateModule],
})
export class OrdersFilterComponent implements OnInit, OnDestroy {
	filters: filter_helper[] = [
		{
			active: false,
			text: 'ORDERS.FILTER.ALL',
			state: undefined,
			count: 0,
			active_class: '!text-gb-3-500 bg-gb-3-500',
			hover_class: 'hover:!text-gb-3-500 hover:bg-gb-3-500',
			id: 'filter-all',
		},
		{
			active: false,
			text: 'ORDERS.FILTER.OVERDUE',
			state: ORDERSTATE.Overdue,
			count: 0,
			active_class: '!text-s-3-500 bg-s-3-500',
			hover_class: 'hover:!text-s-3-500 hover:bg-s-3-500',
			id: 'filter-overdue',
		},
		{
			active: false,
			text: 'ORDERS.FILTER.READYFORINPUT',
			state: ORDERSTATE.Readyforinput,
			count: 0,
			active_class: '!text-s-2-500 bg-s-2-500',
			hover_class: 'hover:!text-s-2-500 hover:bg-s-2-500',
			id: 'filter-ready-for-input',
		},
		{
			active: false,
			text: 'ORDERS.FILTER.READYFORPICKUP',
			state: ORDERSTATE.Readyforpickup,
			count: 0,
			active_class: '!text-bl-1-500 bg-bl-1-500',
			hover_class: 'hover:!text-bl-1-500 hover:bg-bl-1-500',
			id: 'filter-ready-for-pickup',
		},
		{
			active: false,
			text: 'ORDERS.FILTER.PICKEDUP',
			state: ORDERSTATE.Pickedup,
			count: 0,
			active_class: '!text-s-1-500 bg-s-1-500',
			hover_class: 'hover:!text-s-1-500 hover:bg-s-1-500',
			id: 'filter-picked-up',
		},
		{
			active: false,
			text: 'ORDERS.FILTER.CANCELED',
			state: ORDERSTATE.Canceled,
			count: 0,
			active_class: '!text-gb-3-500 bg-gb-3-500',
			hover_class: 'hover:!text-gb-3-500 hover:bg-gb-3-500',
			id: 'filter-canceled',
		},
	];

	subs: Subscription = new Subscription();

	constructor(
		private orderOverviewService: OrderOverviewService,
		private pharmacySettingsSerivce: PharmacySettingsService,
	) {
		this.subs.add(
			this.orderOverviewService.orderCount$.subscribe(
				(x: OrdersCount) => {
					this.set_count(undefined, x.allOrders);
					this.set_count(ORDERSTATE.Overdue, x.overdue);
					this.set_count(ORDERSTATE.Readyforpickup, x.readyForPickup);
					this.set_count(ORDERSTATE.Readyforinput, x.readyForInput);
					this.set_count(ORDERSTATE.Pickedup, x.pickedUp);
					this.set_count(ORDERSTATE.Canceled, x.canceled);
				},
			),
		);

		this.subs.add(
			this.orderOverviewService.curOrderFilterSubject.subscribe(
				(state) => {
					this.reset_filter();
					this.set_filter();
				},
			),
		);

		this.subs.add(
			this.pharmacySettingsSerivce.onPharmacySettingsChanged
				.pipe(skipWhile((x) => x == null))
				.subscribe((settings) => {
					if (settings.overdueStatusTimespanInDays === 0) {
						const idx = this.filters.findIndex(
							(x) => x.state === ORDERSTATE.Overdue,
						);
						if (idx > -1) {
							this.filters.splice(idx, 1);
						}
					}
				}),
		);
	}

	ngOnInit(): void {
		this.reset_filter();
		this.set_filter();
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	reset_filter() {
		for (let filter of this.filters) {
			filter.active = false;
		}
	}

	activate_filter(filter: filter_helper) {
		filter.active = true;
	}

	set_filter() {
		this.activate_filter(
			this.filters.find(
				(x) => x.state == this.orderOverviewService.curORDERSTATEFilter,
			),
		);
	}

	set_count(state: ORDERSTATE | undefined, value: number) {
		const filter = this.filters.find((x) => x.state == state);
		if (filter) {
			filter.count = value;
		}
	}

	async changeFilter(orderState: ORDERSTATE) {
		this.orderOverviewService.changeFilter(orderState);
	}
}
