import { Component, OnInit } from '@angular/core';
import { RowaShellModule } from '../../../../../libs/ui/src/lib/shell/shell.module';

@Component({
	selector: 'app-guest-nav',
	template: `
		<rowa-topbar
			[appProperties]="{ name: '' }"
			logoImage="assets/images/rowa-puc-logo.svg"
		>
		</rowa-topbar>
	`,
	standalone: true,
	imports: [RowaShellModule],
})
export class GuestNavComponent {}
