@if (settings?.simplePickupPrint) {
	<div
		class="m-0 p-0 border-none bg-transparent transform rotate-90 translate-x-[-25%] translate-y-[140%] origin-center h-[29mm] w-[89mm] flex items-center"
	>
		<div class="flex flex-row gap-x-1">
			<div class="flex items-center w-[30mm]">
				<img
					class="w-11/12 h-auto"
					[src]="QRCodeImg"
					[alt]="
						'ORDERS.ORDEROVERVIEW.CANNOT_CREATE_QRCODE'
							| translateCommunicationLanguage
					"
				/>
			</div>
			<div class="text-xs w-[85mm]">
				@if (order?.customerDetails?.fullName) {
					<div>
						{{
							order?.customerDetails?.fullName
								| censorName: isSuperAdmin
						}}
					</div>
				}
				@if (order?.orderNumber) {
					<div>
						{{ 'ORDERS.ORDEROVERVIEW.ORDERNUMBER' | translate }}
						<span>
							{{ order?.orderNumber }}
						</span>
					</div>
				}
			</div>
		</div>
	</div>
}
@if (!settings?.simplePickupPrint) {
	<div id="print-input" class="p-2 m-0 border-none bg-transparent w-[10cm]">
		<div class="p-2 text-4xl text-center">
			{{ settings?.name || '' }}
		</div>
		<div class="flex flex-row justify-between mt-3">
			<div>
				{{ 'OUTPUT_PRINT.ORDER_FROM' | translateCommunicationLanguage }}
			</div>
			<div class="text-right">
				{{ order?.creationDate | date: 'dd.MM.yyyy / HH:mm' }}
				{{ 'OUTPUT_PRINT.TIME_LABEL' | translateCommunicationLanguage }}
			</div>
		</div>
		<hr class="my-3 text-g-1-400" />
		<div class="text-center">
			<div>
				{{ 'OUTPUT_PRINT.TITLE' | translateCommunicationLanguage }}
			</div>
			@if (order?.orderNumber) {
				<div>({{ order.orderNumber }})</div>
			}
		</div>
		<div class="flex justify-center items-center py-5 text-center">
			<img
				class="w-[5cm] h-[5cm]"
				[src]="QRCodeImg"
				[alt]="
					'ORDERS.ORDEROVERVIEW.CANNOT_CREATE_QRCODE'
						| translateCommunicationLanguage
				"
			/>
		</div>
		<div class="text-center">
			<span
				class="px-2 py-1 rounded-full border border-black border-solid"
			>
				1
			</span>
		</div>
		<div class="mt-2 text-center">
			{{
				(settings?.outdoorTerminal
					? 'OUTPUT_PRINT.OUTDOOR_PRINT.FIRSTSTEP'
					: 'OUTPUT_PRINT.INDOOR_PRINT.FIRSTSTEP'
				) | translateCommunicationLanguage
			}}
		</div>
		<div class="text-center">
			{{ (settings?.address || '') + (settings?.address ? ',' : '') }}
			{{ settings?.postalCode || '' }}&nbsp;{{ settings?.city || '' }}
			@if (
				settings?.additionalAddress != null &&
				settings?.additionalAddress.length > 0
			) {
				<div>({{ settings?.additionalAddress || '' }})</div>
			}
			@if (!settings?.outdoorTerminal) {
				<div>
					@if (settings?.openingHoursAsString) {
						<span>
							{{
								'OUTPUT_PRINT.INDOOR_PRINT.OPENINGHOURS'
									| translateCommunicationLanguage
							}}
						</span>
					}
					@if (settings?.openingHoursAsString) {
						<br />
					}
					<span
						[innerHTML]="settings?.openingHoursAsString || ''"
					></span>
				</div>
			}
		</div>
		<div class="mt-3 text-center">
			<span
				class="px-2 py-1 rounded-full border border-black border-solid"
			>
				2
			</span>
		</div>
		<div class="mt-2 text-center">
			{{
				(settings?.outdoorTerminal
					? 'OUTPUT_PRINT.OUTDOOR_PRINT.SECONDSTEP'
					: 'OUTPUT_PRINT.INDOOR_PRINT.SECONDSTEP'
				) | translateCommunicationLanguage
			}}
		</div>
		<hr class="my-3 text-g-1-400" />
		@if (settings && settings?.slogan?.length > 0) {
			<div class="pt-2 text-2xl text-center">
				{{ settings?.slogan || '' }}
			</div>
		}
		<div class="pt-2 text-xl text-center">
			{{ settings?.name || '' }}
		</div>
		<div class="pt-2 text-center">
			@if (settings?.address) {
				<div>
					{{ settings?.address || '' }}
				</div>
			}
			@if (settings?.postalCode || settings?.city) {
				<div>
					{{
						(settings?.postalCode || '') +
							' ' +
							(settings?.city || '')
					}}
				</div>
			}
			@if (settings?.phone) {
				<div>
					{{
						('OUTPUT_PRINT.FOOTER.PHONE_LABEL'
							| translateCommunicationLanguage) +
							' ' +
							settings?.phone || ''
					}}
				</div>
			}
			@if (settings?.email) {
				<div>
					{{
						('OUTPUT_PRINT.FOOTER.MAIL_LABEL'
							| translateCommunicationLanguage) +
							' ' +
							settings?.email || ''
					}}
				</div>
			}
		</div>
	</div>
}
