import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateFn,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => {
	if ((window as any).Cypress?.env().TEST_AUTH) {
		return true;
	}

	const authService = inject(AuthService);
	const router = inject(Router);

	const redirectToLogin = (state: RouterStateSnapshot): UrlTree => {
		return router.createUrlTree(['login'], {
			queryParams: { from: state.url },
		});
	};

	if (authService.isAuthenticated()) {
		return true;
	}

	if (authService.isTokenExpired()) {
		const auth = authService.getPUCToken().pipe(
			map((x) =>
				authService.isAuthenticated() ? true : redirectToLogin(state),
			),
			catchError((err) => of(redirectToLogin(state))),
		);
		return auth;
	}

	return redirectToLogin(state);
};
