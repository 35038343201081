import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subscription, filter } from 'rxjs';
import { GuestNavComponent } from '../../navbar/guest-nav/guest-nav.component';

@Component({
	selector: 'app-logout',
	template: ` <app-guest-nav />`,
	standalone: true,
	imports: [GuestNavComponent],
})
export class LogoutComponent implements AfterViewInit, OnDestroy {
	showLogout: boolean = false;
	subs: Subscription = new Subscription();

	constructor(
		private msalBroadcastService: MsalBroadcastService,
		private router: Router,
	) {}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.subs.add(
			this.msalBroadcastService.inProgress$
				.pipe(
					filter(
						(status: InteractionStatus) =>
							status === InteractionStatus.None,
					),
				)
				.subscribe(() => {
					this.loginAgain();
				}),
		);
	}

	loginAgain() {
		this.showLogout = false;
		this.router.navigateByUrl('/');
	}
}
