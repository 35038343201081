<div #order_overview_wrap class="order-overview-wrap" name="orderOverviewWrap">
	@for (order of ordersDisplay; track order.storageCode) {
		<div
			class="mb-4 border-none shadow-md rounded-xl card order-overview-card"
			[id]="'pickUpEditCard' + order.storageCode"
			@fade
		>
			<app-order-overview-header
				[order]="order"
				(loadData)="loadOrders()"
			/>
		</div>
	}
	@if (showLoader) {
		<div class="flex justify-center">
			<rowa-activity-indicator />
		</div>
	}
	<div class="flex flex-col mt-6 text-base text-center text-g-3-500 gap-y-4">
		@if (!showLoader && ordersDisplay.length <= 0 && !isSearchValueGiven) {
			<div>
				{{ 'ORDERS.ORDEROVERVIEW.NO_ORDERS_CREATED' | translate }}
			</div>
			<div>
				{{ 'ORDERS.ORDEROVERVIEW.NOTE_TO_CREATE_ORDER' | translate }}
				<span
					class="text-bl-1-500 hover:cursor-pointer hover:underline"
					(click)="initCreateNewPickup()"
				>
					{{ 'ORDERS.ORDEROVERVIEW.NEW_PICKUP' | translate }}
				</span>
			</div>
		}
		@if (!showLoader && ordersDisplay.length <= 0 && isSearchValueGiven) {
			<div>
				{{ 'ORDERS.ORDEROVERVIEW.NO_ORDERS_FOUND' | translate }}
				<strong>"{{ searchValue }}"</strong>.
			</div>
			<div>
				{{
					'ORDERS.ORDEROVERVIEW.NOTE_TO_CREATE_NEW_ORDER' | translate
				}}
				<span
					class="text-bl-1-500 hover:cursor-pointer hover:underline"
					(click)="initCreateNewPickup()"
				>
					{{ 'ORDERS.ORDEROVERVIEW.NEW_PICKUP' | translate }}
				</span>
			</div>
		}
	</div>
</div>
