import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription, merge } from 'rxjs';
import { map, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { fade } from 'src/app/core/animations/fade.animation';
import { PharmacySettingsService } from 'src/app/core/services/pharmacy-settings.service';
import { SignalRService } from 'src/app/core/services/signal-r.service';
import {
	CustomerDto,
	GetOrderResponse,
	GetOrderResponseDto,
} from 'src/app/swagger';
import { RowaActivityIndicatorModule } from '../../../../../libs/ui/src/lib/activity-indicator/activity-indicator.module';
import { OrderOverviewHeaderComponent } from '../../../core/components/order-overview-header/order-overview-header.component';
import { OrderOverviewService, TAKE } from './order-overview.service';
import { PageWrapperService } from 'src/app/core/components/page-wrapper/page-wrapper.service';

@Component({
	selector: 'app-order-overview',
	templateUrl: './order-overview.component.html',
	animations: [fade],
	standalone: true,
	imports: [
		OrderOverviewHeaderComponent,
		RowaActivityIndicatorModule,
		TranslateModule,
	],
})
export class OrderOverviewComponent implements OnInit, OnDestroy {
	subs: Subscription = new Subscription();
	isSearchValueGiven: boolean = false;
	searchValue: string = '';
	ordersDisplay: GetOrderResponse[] = [];
	waitUntilSettingsLoadedSub: Subscription = new Subscription();
	showLoader: boolean = true;

	constructor(
		private orderOverviewService: OrderOverviewService,
		private router: Router,
		private signalR: SignalRService,
		private pharmacySettingsService: PharmacySettingsService,
		private pagewrapper: PageWrapperService,
	) {
		this.subs.add(
			merge(
				this.orderOverviewService.fetchOrders$,
				this.orderOverviewService.curOrderFilterSubject,
				this.orderOverviewService.searchValue$,
				this.signalR.orderChangeReceived.pipe(
					tap((_) => {
						this.orderOverviewService.skip = 0;
						if (this.ordersDisplay.length > TAKE) {
							this.orderOverviewService.take =
								this.ordersDisplay.length;
						} else {
							this.orderOverviewService.take = TAKE;
						}
					}),
				),
				this.signalR.afterReminderEmailsWereSentOut,
			)
				.pipe(
					switchMap((_) => this.orderOverviewService.getAllOrders()),
					tap((res: GetOrderResponseDto) => {
						this.orderOverviewService.ordersData$.next(res.orders);
						this.orderOverviewService.orderCount$.next(
							res.ordersCount,
						);
						this.orderOverviewService.showLoader$.next(false);
						this.pagewrapper.searchLoader$.next(false);
					}),
				)
				.subscribe(),
		);

		this.subs.add(
			this.orderOverviewService.ordersData$.subscribe(
				(items: Array<GetOrderResponse>) => {
					this.orderOverviewService.take = TAKE;
					if (this.orderOverviewService.skip === 0) {
						this.ordersDisplay = [];
						this.orderOverviewService.skip = items.length;
					} else {
						this.orderOverviewService.skip += items.length;
					}

					this.ordersDisplay.push(...items);

					if (items.length < TAKE) {
						this.orderOverviewService.allOrderLoaded$.next(true);
					}
				},
			),
		);
		this.subs.add(
			this.orderOverviewService.showLoader$.subscribe(
				(state) => (this.showLoader = state),
			),
		);

		this.subs.add(
			this.signalR.newCustomerData.subscribe(
				(customerDto: CustomerDto) => {
					for (let order of this.ordersDisplay) {
						if (order.customerDetails?.id === customerDto.id) {
							order.customerDetails = { ...customerDto };
						}
					}
				},
			),
		);

		this.waitUntilSettingsLoadedSub =
			this.pharmacySettingsService.onPharmacySettingsChanged
				.pipe(
					skipWhile((x) => x == null),
					take(1),
				)
				.subscribe((_) => {
					this.loadOrders();
				});

		this.subs.add(
			this.orderOverviewService.searchValue$.subscribe(
				(value: string[]) => {
					this.isSearchValueGiven = value?.length > 0;
					this.searchValue = value?.join(' ') ?? '';
				},
			),
		);
	}

	ngOnInit(): void {
		if (this.orderOverviewService.searchValues) {
			this.searchValue = this.orderOverviewService.searchValues.join(' ');
			this.isSearchValueGiven =
				this.orderOverviewService.searchValues != null &&
				this.orderOverviewService.searchValues.length > 0;
		}
	}

	loadOrders() {
		this.orderOverviewService.loadOrders();
		this.waitUntilSettingsLoadedSub?.unsubscribe();
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
		this.orderOverviewService.resetOrdersOverviewOnLeave();
	}

	initCreateNewPickup() {
		this.router.navigate(['orders', 'create']);
	}

	async cancelEditDetailViewAfterCancel() {
		this.orderOverviewService.loadOrders();
	}
}
