import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { smoothHeight } from 'src/app/core/animations/smoothHeight.animation';
import { PharmacySettingsService } from 'src/app/core/services/pharmacy-settings.service';
import { IconsComponent } from '../../../core/components/icons/icons.component';
import { ImportedIconsComponent } from '../../../core/components/imported-icons/imported-icons.component';
import { smoothTransition } from '../../../core/animations/smoothTransistion.animation';

@Component({
	selector: 'app-order-creation',
	templateUrl: './order-creation.component.html',
	animations: [smoothHeight, smoothTransition],
	standalone: true,
	imports: [IconsComponent, ImportedIconsComponent, TranslateModule],
})
export class OrderCreationComponent implements OnInit, OnDestroy {
	@HostListener('window:keydown.enter', ['$event'])
	handleKeydownEnter() {
		if (document.activeElement.id !== 'orderSearch') {
			this.initCreateNewPickup();
		}
	}

	repeatedOrderEnabled: boolean = false;
	subs: Subscription = new Subscription();

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private pharmacySettingsService: PharmacySettingsService,
	) {
		this.subs.add(
			this.pharmacySettingsService.onPharmacySettingsChanged.subscribe(
				(x) => {
					if (x) {
						this.repeatedOrderEnabled = x.enableRepeatedOrders;
					}
				},
			),
		);
	}

	ngOnInit(): void {
		(document.activeElement as HTMLElement).blur();
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	initCreateNewPickup() {
		this.router.navigate(['orders', 'create']);
	}

	createNewRepeatedPickup() {
		this.router.navigate(['create-recurrent'], { relativeTo: this.route });
	}
}
