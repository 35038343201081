<div
	class="fixed left-0 h-full pt-5 transition-all bg-gb-3-500"
	[ngClass]="{ 'w-60': sideBarOpen, 'w-14': !sideBarOpen }"
>
	@for (nav of topSideNavHelper$ | async; track nav; let i = $index) {
		<a
			[routerLink]="nav.url"
			[name]="nav.url"
			class="block py-2 pl-4 pr-2 text-sm text-white border-l-2 border-transparent opacity-80 fill-white hover:bg-gb-4-500"
			routerLinkActive="!text-white !bg-gb-4-500 !border-bl-1-500"
		>
			<app-imported-icons [iconName]="nav.icon" [navbarIcon]="true" />
			@if (showlabels$ | async) {
				<span class="ml-3">
					{{ nav.name | translate }}
				</span>
			}
		</a>
	}
	<div class="bg-white h-[2px] mt-3 mb-1 mx-4"></div>
	@for (nav of downSideNavHelper; track nav; let i = $index) {
		<a
			[routerLink]="nav.url"
			[name]="nav.url"
			class="block py-2 pl-4 pr-2 text-sm text-white border-l-2 border-transparent opacity-80 fill-white hover:bg-gb-4-500"
			routerLinkActive="!text-white !bg-gb-4-500 !border-bl-1-500"
		>
			<app-imported-icons [iconName]="nav.icon" [navbarIcon]="true" />
			@if (showlabels$ | async) {
				<span class="ml-3">
					{{ nav.name | translate }}
				</span>
			}
		</a>
	}
	<a
		class="fixed bottom-0 py-2 pl-4 pr-2 text-sm text-white border-l-2 border-transparent opacity-80 hover:cursor-pointer"
		(click)="toggleSidebar()"
	>
		@if (sideBarOpen) {
			<app-imported-icons
				[iconName]="'navbar.toggle'"
				[navbarIcon]="true"
			/>
		}
		@if (!sideBarOpen) {
			<app-imported-icons
				[iconName]="'navbar.untoggle'"
				[navbarIcon]="true"
			/>
		}
		@if (showlabels$ | async) {
			<span class="ml-3">
				{{ 'SIDENAV.TOGGLE' | translate }}
			</span>
		}
	</a>
</div>

<div
	id="content"
	class="p-4 transition-all overflow-hidden h-[95vh]"
	[ngClass]="{ 'ml-60': sideBarOpen, 'ml-14': !sideBarOpen }"
>
	<app-toast />
	<router-outlet />
</div>
