import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { PharmacySettingsService } from '../services/pharmacy-settings.service';

@Injectable({
	providedIn: 'root',
})
export class RepeatedOrderGuard {
	constructor(
		private pharmacySettingsSerivce: PharmacySettingsService,
		private router: Router,
	) {}
	async canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Promise<boolean | UrlTree> {
		if (
			!this.pharmacySettingsSerivce.onPharmacySettingsChanged.getValue()
		) {
			await this.pharmacySettingsSerivce.loadPharmacySettings();
		}

		if (
			!this.pharmacySettingsSerivce.onPharmacySettingsChanged.getValue()
				.enableRepeatedOrders
		) {
			this.router.navigate(['orders']);
		}

		return this.pharmacySettingsSerivce.onPharmacySettingsChanged.getValue()
			.enableRepeatedOrders;
	}
}
