import { LoggingService } from './logging.service';
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
	constructor(private loggingService: LoggingService) {
		super();
	}

	override handleError(error: Error) {
		console.error(error);
		this.loggingService.logException(error);
	}
}
