import { ChangeDetectorRef, Pipe } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { CommunicationLanguageTranslationService } from '../services/custom-translation.service';

@Pipe({
	name: 'translateCommunicationLanguage',
	pure: false, // required to update the value when the promise is resolved
	standalone: true,
})
export class TranslateCommunicationLanguagePipe extends TranslatePipe {
	constructor(
		customTranslator: CommunicationLanguageTranslationService,
		_changeRef: ChangeDetectorRef,
	) {
		super(customTranslator, _changeRef);
	}
}
