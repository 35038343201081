import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private _refreshTokenInProgress = false;
	private _refreshTokenSubject: BehaviorSubject<any> =
		new BehaviorSubject<any>(null);

	constructor(
		private authService: AuthService,
		private router: Router,
	) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		if (!this.shouldIntercept(request)) {
			return next.handle(request);
		}

		if (this.authService.isAuthenticated()) {
			request = this.addAuthToken(request);
		} else if (this.authService.isTokenExpired()) {
			return this.refreshToken(request, next);
		}

		return next.handle(request).pipe(
			// try to refresh token for 401 errors, throw for others
			catchError((error: HttpErrorResponse) => {
				if (error && error.status == 401) {
					return this.refreshToken(request, next);
				} else {
					return throwError(() => new Error(error.message));
				}
			}),
		);
	}

	private refreshToken(
		request: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		if (this._refreshTokenInProgress) {
			// If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
			// which means the new token is ready and we can retry the request again
			return this._refreshTokenSubject.pipe(
				filter((result) => result !== null),
				take(1),
				switchMap(() => next.handle(this.addAuthToken(request))),
			);
		} else {
			this._refreshTokenInProgress = true;

			// Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
			this._refreshTokenSubject.next(null);

			return this.authService.getPUCToken().pipe(
				switchMap((x: any) => {
					const isAuth = this.authService.isAuthenticated();

					if (!isAuth) {
						this.router.navigateByUrl('/login');
						return EMPTY;
					}

					this._refreshTokenSubject.next(isAuth);
					return next.handle(this.addAuthToken(request));
				}),

				catchError((err) => {
					console.error(
						`Token refresh failed in interceptor: ${err}`,
					);
					this.router.navigateByUrl('/login');
					return EMPTY;
				}),

				// When the call to refreshToken completes we reset the refreshTokenInProgress to false
				// for the next time the token needs to be refreshed
				finalize(() => (this._refreshTokenInProgress = false)),
			);
		}
	}

	addAuthToken(request: HttpRequest<unknown>): HttpRequest<unknown> {
		return request.clone({
			headers: request.headers.set(
				'Authorization',
				`Bearer ${this.authService.authToken}`,
			),
		});
	}

	shouldIntercept(request: HttpRequest<unknown>): boolean {
		if (
			request.url.includes('/api/auth/') ||
			request.url.includes('/api/B2C/')
		)
			return false; // do not intercept auth controller
		return request.url.includes('/api/'); // but all others
	}
}
