import {
	HttpEvent,
	HttpInterceptorFn,
	HttpResponse,
} from '@angular/common/http';
import { catchError, of, tap } from 'rxjs';
import { ToastService } from '../components/toast/toast.service';
import { inject } from '@angular/core';
import { IToast } from '../interfaces/toast-helper.interface';
import { ToastType } from '../enums/toast-type.enum';

export const customHttpInterceptor: HttpInterceptorFn = (req, next) => {
	const toast = inject(ToastService);

	const dateRegex =
		/^\d{4}(-\d{2}){2}T\d{2}:\d{2}(?::\d{2}(?:\.\d{1,7}(?:\+\d{2}:\d{2})?)?)?$/;
	const utcDateRegex =
		/^\d{4}(-\d{2}){2}T\d{2}:\d{2}(?::\d{2}(?:\.\d{1,7}(?:\+\d{2}:\d{2})?)?)?Z$/;

	const convertDates = (
		object: unknown,
		parent?: Record<string, unknown> | unknown[],
		key?: number | string,
	) => {
		if (object === null) {
			return;
		}

		if (typeof object === 'string') {
			if (dateRegex.test(object)) {
				if (!utcDateRegex.test(object)) {
					parent[key] = new Date(object + 'Z');
				} else {
					parent[key] = new Date(object);
				}
			}
		} else if (Array.isArray(object)) {
			for (let i = 0; i < object.length; i++)
				convertDates(object[i], object, i);
		} else {
			for (const key of Object.keys(object as Record<string, unknown>)) {
				convertDates(
					(object as Record<string, unknown>)[key],
					object as Record<string, unknown>,
					key,
				);
			}
		}
	};

	return next(req).pipe(
		tap((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {
				convertDates(event.body);
			}
		}),
		catchError((err) => {
			toast.makeToast(<IToast>{
				title: 'ORDERS.TOAST.TITLE.ERROR',
				message: err.error,
				showIcon: true,
				type: ToastType.ERROR,
				durationMilli: 5000,
			});
			return of(err);
		}),
	);
};
