import { animate, style, transition, trigger } from '@angular/animations';

export const smoothTransition = trigger('grow', [
	transition('void <=> *', []),
	transition(
		'* <=> *',
		[
			style({ height: '{{startHeight}}px', opacity: 0 }),
			animate('.75s ease'),
		],
		{
			params: { startHeight: 0 },
		},
	),
]);
