<div
	class="bg-white border-none shadow-md rounded-xl"
	id="newPickUpCardWrapper"
>
	<div class="flex justify-between h-24 gap-4 text-2xl">
		<div
			class="flex items-center justify-center flex-grow w-1/3 cursor-pointer"
			(click)="initCreateNewPickup()"
			id="newPickup"
		>
			<app-icons [iconName]="'faPlus'" class="mr-2 text-bl-1-500" />
			{{ 'ORDERS.NEWPICKUP.TITLE' | translate }}...
		</div>
		@if (repeatedOrderEnabled) {
			<div
				class="relative flex items-center justify-center flex-grow-0 h-full"
			>
				<div class="flex justify-center items-center relative z-[1]">
					<div
						class="flex items-center justify-center p-1 text-xs bg-white text-g-1"
					>
						<p>or</p>
					</div>
				</div>
				<div class="absolute inset-0 z-0 overflow-hidden">
					<div class="absolute inset-0 z"></div>
					<div
						class="absolute inset-0 flex items-center justify-center w-full h-full"
					>
						<div class="h-[90%] w-px bg-g-1-400"></div>
					</div>
				</div>
			</div>
		}
		@if (repeatedOrderEnabled) {
			<div
				class="flex items-center justify-center flex-grow w-1/3 cursor-pointer"
				(click)="createNewRepeatedPickup()"
				id="newScheduledPickup"
			>
				<app-imported-icons
					[iconName]="'orders.repeated'"
					class="relative mt-2 mr-4 text-bl-1-500"
				/>
				{{ 'ORDERS.REPEATED_PICKUP.TITLE' | translate }}...
			</div>
		}
	</div>
</div>
