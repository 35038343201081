import { AsyncPipe, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subject, interval, of } from 'rxjs';
import { delayWhen, map, skipWhile, switchMap } from 'rxjs/operators';
import { SideNavHelper } from 'src/app/core/interfaces/side-nav-helper.interface';
import { PharmacySettingsService } from 'src/app/core/services/pharmacy-settings.service';
import { ImportedIconsComponent } from '../../../core/components/imported-icons/imported-icons.component';
import { ToastComponent } from '../../../core/components/toast/toast/toast.component';

@Component({
	selector: 'app-side-nav',
	templateUrl: './side-nav.component.html',
	standalone: true,
	imports: [
		NgClass,
		RouterLink,
		RouterLinkActive,
		ImportedIconsComponent,
		ToastComponent,
		RouterOutlet,
		AsyncPipe,
		TranslateModule,
	],
})
export class SideNavComponent {
	topSideNavHelper$: Observable<SideNavHelper[]> =
		this.pharmacySettingsService.onPharmacySettingsChanged.pipe(
			skipWhile((x) => x == null),
			map((x) => {
				if (x?.enableRepeatedOrders && x?.enableStoreRX) {
					return <SideNavHelper[]>[
						...this.orders,
						...this.reccurentOrdersFeature,
						...this.stockRxFeature,
					];
				} else if (x?.enableRepeatedOrders) {
					return <SideNavHelper[]>[
						...this.orders,
						...this.reccurentOrdersFeature,
					];
				} else if (x?.enableStoreRX) {
					return <SideNavHelper[]>[
						...this.orders,
						...this.stockRxFeature,
					];
				} else {
					return <SideNavHelper[]>[...this.orders];
				}
			}),
		);

	orders: SideNavHelper[] = [
		{
			name: 'SIDENAV.ORDERS',
			url: 'orders',
			icon: 'navbar.bag',
		},
	];

	reccurentOrdersFeature: SideNavHelper[] = [
		{
			name: 'SIDENAV.SCHEDULED_ORDERS',
			url: 'scheduled-orders',
			icon: 'navbar.scheduled',
		},
		{
			name: 'SIDENAV.OUT_OF_STOCK_ARTICLES',
			url: 'out-of-stock-articles',
			icon: 'navbar.out-of-stock-articles',
		},
		{
			name: 'SIDENAV.DISPENSED_ARTICLES',
			url: 'dispensed-articles',
			icon: 'navbar.dispensed-articles',
		},
	];

	stockRxFeature: SideNavHelper[] = [
		{
			name: 'SIDENAV.STOCK_MANAGEMENT',
			url: 'stock-management',
			icon: 'navbar.stockManagement',
		},
		{
			name: 'SIDENAV.STOCK_INPUT',
			url: 'stock-input',
			icon: 'navbar.stock-input',
		},
	];

	downSideNavHelper: Array<SideNavHelper> = [
		{
			name: 'SIDENAV.PHARMACYSETTINGS',
			url: 'pharmacysettings',
			icon: 'navbar.settings',
		},
		{
			name: 'SIDENAV.WORKSTATIONSETTINGS',
			url: 'workstationsettings',
			icon: 'navbar.workstation',
		},
	];

	sideBarOpen: boolean = false;
	showlabels: Subject<boolean> = new Subject<boolean>();
	showlabels$: Observable<boolean> = this.showlabels.pipe(
		switchMap((isOpen) =>
			of(isOpen).pipe(
				delayWhen((isOpen) => (isOpen ? interval(150) : of(undefined))),
			),
		),
	);

	constructor(private pharmacySettingsService: PharmacySettingsService) {}

	toggleSidebar() {
		this.sideBarOpen = !this.sideBarOpen;
		this.showlabels.next(this.sideBarOpen);
	}
}
