import {
	MsalGuardConfiguration,
	MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
	BrowserCacheLocation,
	Configuration,
	InteractionType,
	IPublicClientApplication,
	LogLevel,
	PublicClientApplication,
} from '@azure/msal-browser';
import { isDevMode } from '@angular/core';
import { authConfig } from 'src/environments/environment';

const isIE =
	window.navigator.userAgent.indexOf('MSIE ') > -1 ||
	window.navigator.userAgent.indexOf('Trident/') > -1;

const PUC_AUTH_SCOPE = isDevMode()
	? 'https://rowaauthtest.onmicrosoft.com/f20a60ba-e935-457a-bbe5-ba6bbf94dd47/puc.auth'
	: 'https://rowab2cprod.onmicrosoft.com/3e89c816-5090-40ce-bed0-f28ad100744c/puc.auth';

const msalConfig: Configuration = {
	auth: {
		clientId: authConfig.clientId,
		authority: authConfig.b2cPolicies.authorities.signUpSignIn.authority,
		knownAuthorities: [authConfig.b2cPolicies.authorityDomain],
		redirectUri: '/auth',
		postLogoutRedirectUri: '/logout',
	},
	cache: {
		cacheLocation: BrowserCacheLocation.LocalStorage,
		storeAuthStateInCookie: isIE,
	},
	system: {
		loggerOptions: {
			loggerCallback(logLevel: LogLevel, message: string) {
				// if you want to log the auth stuff, then uncomment next line
				//console.log(message);
			},
			logLevel: LogLevel.Verbose,
			piiLoggingEnabled: true,
		},
		loadFrameTimeout: 240000,
		allowRedirectInIframe: true,
		allowNativeBroker: false,
	},
};

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	// try to find previously set language, if any
	let lang = '';
	try {
		let localRegion = localStorage.getItem('LOCAL_REGION');
		if (localRegion) {
			lang = JSON.parse(localRegion);
		} else {
			lang = window.navigator.language;
		}

		const sep = lang.indexOf('-');
		if (sep != -1) lang = lang.substring(0, sep);
	} catch (E) {
		lang = 'en';
		console.error(E);
	}

	return {
		interactionType: InteractionType.Redirect,
		loginFailedRoute: '/login-failed',
		authRequest: {
			scopes: [PUC_AUTH_SCOPE],
			extraQueryParameters: {
				lang: lang,
			},
		},
	};
}

//idk why this has to be added for local stuff
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set('/api/auth/', [PUC_AUTH_SCOPE]);
	protectedResourceMap.set('http://localhost:5000/api/auth/pharmacy-list', [
		PUC_AUTH_SCOPE,
	]);
	protectedResourceMap.set('http://localhost:5000/api/auth/is-super-admin', [
		PUC_AUTH_SCOPE,
	]);
	protectedResourceMap.set(
		'http://localhost:5000/api/auth/is-internal-user',
		[PUC_AUTH_SCOPE],
	);
	protectedResourceMap.set('http://localhost:5000/api/auth/new-token', [
		PUC_AUTH_SCOPE,
	]);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}
